/* Buttons */
.dropdown.dropdown-container {
  background-color: #2b08b3a8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-inline: 5px;
}

.dropdown.dropdown-container button:hover {
  background-color: #5e81ac87;
}