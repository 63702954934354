/* src/App.css */
.App {
  text-align: center;
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

form {
  margin: 20px 0;
}

.category-table {
  margin-top: 20px;
}
/* General background */
body {
  background-color: #ECEFF4;
  color: #2E3440;
}

/* Headers and important text */
h1, h2, h3, label {
  color: #2E3440;
}

/* Input fields styling */
input[type="number"], input[type="text"] {
  border: 2px solid #5e81ac87;
  background-color: white;
  color: #2E3440;
}

input[type="number"]:focus, input[type="text"]:focus {
  border-color: #A3BE8C;
  outline: none;
}

/* Buttons */
button {
  background-color: #5e81ac87;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #A3BE8C;
}

/* Table styles */
table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
}

th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #5e81ac87;
}

th {
  background-color: #A3BE8C;
  color: white;
}

/* Budget overview */
.budget {
  border-color: #5e81ac87;
  background-color: white;
}

.category-table {
  background-color: white;
  border-color: #5e81ac87;
}

/* Modal Styling */
.modal-content {
  background-color: white;
  color: #2E3440;
}

.modal-header, .modal-footer {
  background-color: #A3BE8C;
  color: white;
}

.category-table {
  height: fit-content;
  max-height: 24rem; /* Example max height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex-grow: 1;
  overflow-y: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th, .table-container td {
  padding: 0.5rem;
  border: 1px solid #ddd;
}
