:root {
    --font-size-base: 1rem;
    --padding-base: 1rem;
    --border-radius: 0.3125rem; /* 5px */
    --box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1); /* 10px */
  }
  
  .register-container {
    width: 90%;
    max-width: 25rem; /* 400px */
    margin: 3.125rem auto; /* 50px */
    text-align: center;
    padding: var(--padding-base);
    border: 0.0625rem solid #ddd; /* 1px */
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: #fff;
  }
  
  .register-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .register-img {
    width: 50%;
    height: auto;
  }
  
  h2 {
    margin: 1.25rem 0; /* 20px */
    font-size: 1.5rem;
    font-weight: normal;
  }
  
  .register-form .form-group {
    margin-bottom: 0.9375rem; /* 15px */
    text-align: left;
  }
  
  .register-form .form-group label {
    display: block;
    margin-bottom: 0.3125rem; /* 5px */
  }
  
  .register-form .form-group input {
    width: 100%;
    padding: 0.625rem; /* 10px */
    border: 0.0625rem solid #ccc; /* 1px */
    border-radius: 0.25rem; /* 4px */
    font-size: var(--font-size-base);
  }
  
  .btn-register {
    width: 100%;
    padding: var(--padding-base);
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem; /* 4px */
    cursor: pointer;
    font-size: var(--font-size-base);
  }
  
  .btn-register:hover {
    background-color: #0056b3;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 37.5rem) { /* 600px */
    .register-container {
      padding: 0.9375rem; /* 15px */
    }
  
    h2 {
      font-size: 1.25rem;
    }
  
    .register-form .form-group input {
      padding: 0.5rem; /* 8px */
      font-size: 0.875rem;
    }
  
    .btn-register {
      padding: 0.5rem; /* 8px */
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 25rem) { /* 400px */
    .register-container {
      padding: 0.625rem; /* 10px */
    }
  
    h2 {
      font-size: 1.125rem;
    }
  
    .register-form .form-group input {
      padding: 0.375rem; /* 6px */
      font-size: 0.75rem;
    }
  
    .btn-register {
      padding: 0.375rem; /* 6px */
      font-size: 0.75rem;
    }
  }

.alternative-login {
    margin-top: 1.25rem; /* 20px */
}

.register-link {
    color: #0366d6;
    text-decoration: none;
}
