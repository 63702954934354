.login-container {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 1.25rem; /* 20px */
    border: 1px solid #ddd;
    border-radius: 0.3125rem; /* 5px */
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1); /* 0 0 10px rgba(0, 0, 0, 0.1) */
    background-color: #fff;
}

.login-logo {
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.logo-img {
    max-width: 50%;
}

h2 {
    margin: 1.25rem 0; /* 20px 0 */
    font-size: 1.5rem;
    font-weight: normal;
}

.login-form .form-group {
    margin-bottom: 0.9375rem; /* 15px */
    text-align: left;
}

.login-form .form-group label {
    display: block;
    margin-bottom: 0.3125rem; /* 5px */
}

.login-form .form-group input {
    width: 100%;
    padding: 0.5rem; /* 8px */
    border: 1px solid #ccc;
    border-radius: 0.25rem; /* 4px */
}

.form-options {
    margin-bottom: 0.9375rem; /* 15px */
    text-align: right;
}

.forgot-password-link {
    font-size: 0.75rem;
    color: #0366d6;
    text-decoration: none;
}

.btn-login {
    width: 100%;
    padding: 0.625rem; /* 10px */
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 0.25rem; /* 4px */
    cursor: pointer;
}

.btn-login:hover {
    background-color: #218838;
}

.alternative-login {
    margin-top: 1.25rem; /* 20px */
}

.sign-up-link {
    display: block;
    margin-top: 0.625rem; /* 10px */
    font-size: 0.75rem;
    color: #0366d6;
    text-decoration: none;
}
